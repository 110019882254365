import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Athom_Homey/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Homey Smarthome Gateway",
  "path": "/Software/Other_Platforms/Athom_Homey/",
  "dateChanged": "2019-08-20",
  "author": "Mike Polinowski",
  "excerpt": "The Homey gateway functions as a bridge between the wireless Z-Wave network and our INSTAR Full HD and HD Camera. Integrate all your IoT devices into a combined smarthome.",
  "social": "/images/Search/P_SearchThumb_Homey.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Homey_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Homey"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='omey Smarthome Gateway' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Homey gateway functions as a bridge between the wireless Z-Wave network and our INSTAR Full HD and HD Camera. Integrate all your IoT devices into a combined smarthome.' image='/images/Search/P_SearchThumb_Homey.png' twitter='/images/Search/P_SearchThumb_Homey.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Athom_Homey/' locationFR='/fr/Software/Other_Platforms/Athom_Homey/' crumbLabel="Homey" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "homey",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#homey",
        "aria-label": "homey permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Homey`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software"
        }}>{`Software`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#other"
            }}>{`Other`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#homey"
                }}>{`Homey`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-your-cameras-snapshot"
            }}>{`Adding your Cameras Snapshot`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#control-your-camera-manually"
            }}>{`Control your Camera manually`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#notifying-homey-when-the-camera-alarm-is-triggered-alarm-server"
            }}>{`Notifying Homey when the Camera Alarm is triggered (Alarm Server)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-zigbeez-wave-sensors-to-control-your-camera"
            }}>{`Using Zigbee/Z-Wave Sensors to control your Camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#time-scheduled-camera-control"
            }}>{`Time scheduled Camera control`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#daynight-schedule"
                }}>{`Day\\&Night Schedule`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#manual-schedule"
                }}>{`Manual Schedule`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>{`In this tutorial we want to show how you can add an INSTAR IP camera to the `}<a parentName="p" {...{
        "href": "https://www.athom.com/homey/"
      }}>{`Athom Homey`}</a>{` Smarthome system. We will start by adding a live-image from our camera (a single snapshot from our camera not the live video stream). And control our camera through it's `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`HTTP command interface`}</a>{`. As a back-channel we will also add our cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm-Server`}</a>{` so we are able to contact the Homey smarthome gateway when an alarm was triggered on our camera.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Athom_Homey/"
      }}>{`quick overview can be found here`}</a>{`.`}</p>
    <h2 {...{
      "id": "adding-your-cameras-snapshot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-cameras-snapshot",
        "aria-label": "adding your cameras snapshot permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Cameras Snapshot`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2be722723a2a2e007c5b71b816708c54/aa440/INSTAR_Cameras_with_Athom_Homey_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGElEQVQoz23QQUhTcRwH8N/2tve2/d/25lsUuGqNUSppjqAULELDrJiDUTltFkMtRUlXYBBlUISXiqiDhaY2F5vTbYrpKooZdula1+Yx6KYbTH3v/f/vH89zl+/39Dl8v3AmNNsUyAT8K9dbc7dactHTydDxQZOVN3OCgByC2SHyexFj/frxAyGytF1SpG1F2pIVsvX3F9S0v/denmsILF70f+rwrfY0/rgXnl79nnv8aFQPHEIiQg4Aw3J6jlJKiKqqVCVEpVQtrsPR8Lvqjrj3SrI+kDkfWGlt+vJ0ZJlSuvZtDUDHWkXOKgIw2dQ8pVSjWhEtC+tQcWOyMjxTcy3uDc7WXUqd9X/2XXgQ7ulqbvEZWbMZCRZeBDAspaP/wZ6Bcc/NqVOR5LnIYm0wXhfMVtZeBQAdY+JQGYvsLO8AYDOx+K4iVFUpwRrezIPr9phrYKKqf/pYb6yqc8bbueA5ETJYTEZeNFrsRoudQyKAMZ6I0lJJKRRwsYgLm7gk4T8/ofzuK+edN+VDk+7et0e6pqq75w/VtwPHGni73mJjzHbT7uZ0KiPLkizJBBOiKIRSspGHPSMvnMMvT3Y/OTg47u6bqOhLHGhoA47VW8sYZNMjgdWwIbmwRDDZ2ZEwVrGCMaV4Iw/7Rp877z9r7n+4f3jMPfT6cCTmamxjzCzD2xjEGyw8ywsAukRWe1uRZYwxVuRd/PsfSKMKb3Lh9pgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2be722723a2a2e007c5b71b816708c54/e4706/INSTAR_Cameras_with_Athom_Homey_01.avif 230w", "/en/static/2be722723a2a2e007c5b71b816708c54/d1af7/INSTAR_Cameras_with_Athom_Homey_01.avif 460w", "/en/static/2be722723a2a2e007c5b71b816708c54/7f308/INSTAR_Cameras_with_Athom_Homey_01.avif 920w", "/en/static/2be722723a2a2e007c5b71b816708c54/e1c99/INSTAR_Cameras_with_Athom_Homey_01.avif 1380w", "/en/static/2be722723a2a2e007c5b71b816708c54/2b305/INSTAR_Cameras_with_Athom_Homey_01.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2be722723a2a2e007c5b71b816708c54/a0b58/INSTAR_Cameras_with_Athom_Homey_01.webp 230w", "/en/static/2be722723a2a2e007c5b71b816708c54/bc10c/INSTAR_Cameras_with_Athom_Homey_01.webp 460w", "/en/static/2be722723a2a2e007c5b71b816708c54/966d8/INSTAR_Cameras_with_Athom_Homey_01.webp 920w", "/en/static/2be722723a2a2e007c5b71b816708c54/445df/INSTAR_Cameras_with_Athom_Homey_01.webp 1380w", "/en/static/2be722723a2a2e007c5b71b816708c54/293e0/INSTAR_Cameras_with_Athom_Homey_01.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2be722723a2a2e007c5b71b816708c54/81c8e/INSTAR_Cameras_with_Athom_Homey_01.png 230w", "/en/static/2be722723a2a2e007c5b71b816708c54/08a84/INSTAR_Cameras_with_Athom_Homey_01.png 460w", "/en/static/2be722723a2a2e007c5b71b816708c54/c0255/INSTAR_Cameras_with_Athom_Homey_01.png 920w", "/en/static/2be722723a2a2e007c5b71b816708c54/b1001/INSTAR_Cameras_with_Athom_Homey_01.png 1380w", "/en/static/2be722723a2a2e007c5b71b816708c54/aa440/INSTAR_Cameras_with_Athom_Homey_01.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2be722723a2a2e007c5b71b816708c54/c0255/INSTAR_Cameras_with_Athom_Homey_01.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Switch to the `}<strong parentName="li">{`Devices`}</strong>{` tab and check if you are able to add virtual devices.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`If you can't find them, the are still hidden as `}<em parentName="li">{`experimental features`}</em>{`.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Switch to the `}<strong parentName="li">{`More`}</strong>{` tab and enter the `}<strong parentName="li">{`Settings`}</strong>{` menu. Click on `}<strong parentName="li">{`Experiments`}</strong>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/aa440/INSTAR_Cameras_with_Athom_Homey_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAklEQVQozz3QPYjTYBjA8Sd50yT9SNIkvR6xXbxBOES4TQcHRw8sfiB3Org4yIGz46Hn9VQo6qCLUGxPLYrtoUM9nTz8wg/8GgRXqaBobdM2yfMmaa6vVFH4r7/lD3bnhzvoUXTRHVDPZWx0ubQCADwf50AiIBOQEoJqKglCuBgBgYAoAAC3PR8D2+4NXOw7bt/x+o4XbbLVSnnuyLH9B+f3FQ7Pzh7aW5g7UJjfkp0QZVmU45Isy7IERN5mifCr3R64HvWDIBwGYeQHw8+fPjLGut0upb7dtbvvPwzDcM/uXYZhWJaVy+UsazIzmds5PQE/v39z+g51ER3PRxr4/qvnG63W1ydPnzXW7jXW7t8+W2w+eDizY1rXdcM0DcPUdT2pmjNTGnRaLbfdCXr9Pw2obb97ucEYa66vA0AsqfNqhkiKlTXy+XxKUYDnCBEAhKksAaS+54f4Lz+I3rx+Yffs2p27ALyo6ERWY4m0kU5pmiYI41c8BwCwNQOAiEhDpAHSwHGRMXb+QpFLKlrW4hMKSWhiSgdOWjhxvFGvV6rVcrlcrVRv3KqvXjkDiPSv/I+LK0sAQFIaSar8GJsApHbzOmMsiqJwGI5Gm4yxzpe3gJ6HGCD6iL7jeGN8bgk4XkzrQkoVUmpM0YCLL5w6evVxsdRcvNQ8fbG5WHq0fK128jewlhAspi3YAAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a24e94f2aaebdfd29bc898cbe024ee21/e4706/INSTAR_Cameras_with_Athom_Homey_02.avif 230w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/d1af7/INSTAR_Cameras_with_Athom_Homey_02.avif 460w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/7f308/INSTAR_Cameras_with_Athom_Homey_02.avif 920w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/e1c99/INSTAR_Cameras_with_Athom_Homey_02.avif 1380w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/2b305/INSTAR_Cameras_with_Athom_Homey_02.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a24e94f2aaebdfd29bc898cbe024ee21/a0b58/INSTAR_Cameras_with_Athom_Homey_02.webp 230w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/bc10c/INSTAR_Cameras_with_Athom_Homey_02.webp 460w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/966d8/INSTAR_Cameras_with_Athom_Homey_02.webp 920w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/445df/INSTAR_Cameras_with_Athom_Homey_02.webp 1380w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/293e0/INSTAR_Cameras_with_Athom_Homey_02.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a24e94f2aaebdfd29bc898cbe024ee21/81c8e/INSTAR_Cameras_with_Athom_Homey_02.png 230w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/08a84/INSTAR_Cameras_with_Athom_Homey_02.png 460w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/c0255/INSTAR_Cameras_with_Athom_Homey_02.png 920w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/b1001/INSTAR_Cameras_with_Athom_Homey_02.png 1380w", "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/aa440/INSTAR_Cameras_with_Athom_Homey_02.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a24e94f2aaebdfd29bc898cbe024ee21/c0255/INSTAR_Cameras_with_Athom_Homey_02.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Activate `}<strong parentName="li">{`Virtual Devices`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Back to the devices tab you will now be able to add a `}<strong parentName="li">{`Virtual IP Camera`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Install`}</strong>{` to add it to your list of devices.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dad0aa41328b32658240198d1b05d433/aa440/INSTAR_Cameras_with_Athom_Homey_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACD0lEQVQoz2MIDAiIiooN8Av29fT38woM9PYPjiotbDmUVnTA13dnsvf+PI8j7T7XnXRdZWQE5aQl1dVVtDTVlFTVPKxVGF6/evX///9///79h4Eduw7+////7tGrgVaTvQP3RXvuy/e6bKJiw8/PxM/FJikpoqQsJyUnZ2coxfD6NUjzX5Duf3/BRqzcvPfy/bfLN1ww8pxnG7zRy29rrO8RB6skbWMvXaNgU9sIW9c4c8d4P3c3hrevX8Nt/vMXRJZMWCodM0s7aZlW1HKD0BWWges8/HYEBLWnpHblZ0/Kzp2WXzwjLqOvOCOX4eiVg5dfXDp7/9TZB6cvv7zy+PPtzJ7pYkkz1NLmayQs0olaqh+23Dxog3tAcX5WUXtxbVJGe1VBS0hMQ0VmHMO5R5fufX96+/PDu18e3/7y+NW/l5lTZgtlTFHOnaOSNk8jcaF2zGL9yHXquh6hbt5h8Z1JSd0tRZ2h0Z0R0ekMb16/QnN23swFPHn9CoUzFHJmK6fPVU+ap52wRkPXQl5cTFHN0MrcMczZ0d7Kxc3emOHtG3Q/58yZz17SJVU+VbpwunzOLKX02eppy1X1LYX4OAR5uWXlpBXVlKUVVRxM5BlOnjpx5969mzdv3rx16/rNm0/u34+b2M9d3yXXMFmuaqpM8TTFvBnq+YuV9U2EedhFBAWUlZW0NDXkldVtjWQAzUsSLcUd1CoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dad0aa41328b32658240198d1b05d433/e4706/INSTAR_Cameras_with_Athom_Homey_03.avif 230w", "/en/static/dad0aa41328b32658240198d1b05d433/d1af7/INSTAR_Cameras_with_Athom_Homey_03.avif 460w", "/en/static/dad0aa41328b32658240198d1b05d433/7f308/INSTAR_Cameras_with_Athom_Homey_03.avif 920w", "/en/static/dad0aa41328b32658240198d1b05d433/e1c99/INSTAR_Cameras_with_Athom_Homey_03.avif 1380w", "/en/static/dad0aa41328b32658240198d1b05d433/2b305/INSTAR_Cameras_with_Athom_Homey_03.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dad0aa41328b32658240198d1b05d433/a0b58/INSTAR_Cameras_with_Athom_Homey_03.webp 230w", "/en/static/dad0aa41328b32658240198d1b05d433/bc10c/INSTAR_Cameras_with_Athom_Homey_03.webp 460w", "/en/static/dad0aa41328b32658240198d1b05d433/966d8/INSTAR_Cameras_with_Athom_Homey_03.webp 920w", "/en/static/dad0aa41328b32658240198d1b05d433/445df/INSTAR_Cameras_with_Athom_Homey_03.webp 1380w", "/en/static/dad0aa41328b32658240198d1b05d433/293e0/INSTAR_Cameras_with_Athom_Homey_03.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dad0aa41328b32658240198d1b05d433/81c8e/INSTAR_Cameras_with_Athom_Homey_03.png 230w", "/en/static/dad0aa41328b32658240198d1b05d433/08a84/INSTAR_Cameras_with_Athom_Homey_03.png 460w", "/en/static/dad0aa41328b32658240198d1b05d433/c0255/INSTAR_Cameras_with_Athom_Homey_03.png 920w", "/en/static/dad0aa41328b32658240198d1b05d433/b1001/INSTAR_Cameras_with_Athom_Homey_03.png 1380w", "/en/static/dad0aa41328b32658240198d1b05d433/aa440/INSTAR_Cameras_with_Athom_Homey_03.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dad0aa41328b32658240198d1b05d433/c0255/INSTAR_Cameras_with_Athom_Homey_03.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`You can now add the path to your cameras live video snapshot. INSTAR HD and Full HD camera's offer three different JPG paths with descending resolution. Simply replace the IP address and in the front with your camera's IP address and the username and password with your camera login:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.x.x/tmpfs/snap.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.x.x/tmpfs/auto.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.x.x/tmpfs/auto2.jpg?usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
`}</code></pre></div>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Click ok to save the path and back in the `}<strong parentName="li">{`Devices`}</strong>{` tab click on your camera.`}</li>
    </ol>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`You should now be shown a snapshot of the live video from your camera. Click on the button below to refresh the image.`}</li>
    </ol>
    <h2 {...{
      "id": "control-your-camera-manually",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#control-your-camera-manually",
        "aria-label": "control your camera manually permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Control your Camera manually`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/aa440/INSTAR_Cameras_with_Athom_Homey_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB40lEQVQoz22PS2sTYRSGz+T75j7fTDJjW1OmuKgLf4ELLyhYS8QUSzVRRGKldeEvcCleF0KoF7DY0NTYGsSAiG02rgQVLYGaZmMmYqOoCJqJ1oVDQzJHOsWN9OFw4F085+WAFbEokUKcwIFIQBY4tcfQBZ7wBGgwPOFEntu7a2cyefJ44sTR4cThwXhscGR8ZADGx8ZSqdHYcDI+lDg4MJQ8ltrRv50XBElWBFESJVFWFACYmryNiH88b22t1Wq1EPFbbRF+/Wyurv5uLi15nuc2XUQ8d/YMY6w3IBqN2rbNmDY7k0HEdruNiH6ng4iNegkqy+W35eVHV64+ebpwf/bBy1evj8RjjLGuru7IOqZlbaGU3sve3UR2G98/f/lqbusHqhDJ4ERmmeE+2zYtC0IcRyjlRQDIZiY3kavVd+VKxertA1GhapjKRtjQDEPfeBUACCEAMJ2587+8UgKjZyvrjlLNIIouaBHgxP379hQKhVwuN71ONp9/mE5PFOcfb8i+73eCEz/qJQipKsgqUfVANgHI6OlT/0p83/cR0XUbpTcvMMjBDprnZ0AI6yGmU6ZTzRCYAZx04NDuqefX0wsXJooXbxQv3Xx2+Vr+/NxcZqX+seo4Tq3mONX3Hz4t5m/9BUQDAbe3lIhjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/e4706/INSTAR_Cameras_with_Athom_Homey_04.avif 230w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/d1af7/INSTAR_Cameras_with_Athom_Homey_04.avif 460w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/7f308/INSTAR_Cameras_with_Athom_Homey_04.avif 920w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/e1c99/INSTAR_Cameras_with_Athom_Homey_04.avif 1380w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/2b305/INSTAR_Cameras_with_Athom_Homey_04.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/a0b58/INSTAR_Cameras_with_Athom_Homey_04.webp 230w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/bc10c/INSTAR_Cameras_with_Athom_Homey_04.webp 460w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/966d8/INSTAR_Cameras_with_Athom_Homey_04.webp 920w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/445df/INSTAR_Cameras_with_Athom_Homey_04.webp 1380w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/293e0/INSTAR_Cameras_with_Athom_Homey_04.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/81c8e/INSTAR_Cameras_with_Athom_Homey_04.png 230w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/08a84/INSTAR_Cameras_with_Athom_Homey_04.png 460w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/c0255/INSTAR_Cameras_with_Athom_Homey_04.png 920w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/b1001/INSTAR_Cameras_with_Athom_Homey_04.png 1380w", "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/aa440/INSTAR_Cameras_with_Athom_Homey_04.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/30ef1b66fbca3adb4042a2ffbf4d6ec6/c0255/INSTAR_Cameras_with_Athom_Homey_04.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`We can use a `}<strong parentName="li">{`Virtual Button`}</strong>{` to enable us to send a `}<a parentName="li" {...{
          "href": "/en/Advanced_User/CGI_Commands/"
        }}>{`CGI command`}</a>{` to our camera. To do this, go to the `}<strong parentName="li">{`Devices`}</strong>{` tab and click on the `}<strong parentName="li">{`+`}</strong>{` in the top right corner to add a new device.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Choose to add a `}<strong parentName="li">{`Virtual Button`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click  `}<strong parentName="li">{`Install`}</strong>{` to add it to the list of your devices.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/aa440/INSTAR_Cameras_with_Athom_Homey_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3ElEQVQoz2PIbtxfUHW0pOJ0R9XFfP8TdR63oq1KFVRk5GTlJSQkRCUk1CQkXLi5t69f+fvr159fvvz+9u33t2+/vn379vo1w9evXz98+vnu3KX///+XZp1IdT7nb54nKiEgLS0jJSUlKSkpLS3Nxc29Z+Pq//////ry5c+XL3+/fvvz/ceXJ08Yjlx5cuDck73ztp298io2ZW+o11EPyyxJaRFZWTkZGZB+GRkZHh6e/Xu2/f///9v3nz++fP3549fPX39+/v7LoJoyTzNhkXbCKoOwlWaBq92D9tpbpUlLi8rJyUuCgZycHC8v775dW//////5y7dvP359+/4ThL79YFDMmq2SOk89foFW9BLdsKVmoVssbJLlZMXl5RVERETExMTk5eX5+PgQmiE6wYhBtnC6QvZs5bR56kkLtGIX6UWtM7KPV5CTlFdQVFBQkJOTk5eX5+fn37cbm2aJsikyhdMVcmcpZ8xVS5qnlbjK0ClOQU5KQVFRHgwUFBQEBQX3796GRbNwzQTx8slShdPk8mYqZcxWS1+m7xarJC+tqKSsqAiyXFFRUUhICLtm0ZZ+sbqJcjXTpEumKeTPUM5foucZrawgq6ysoqSkpKioqKSkJCwsDAltNM0Au9IYJk3p1Q8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a37ae4356f43067c3fb6e97a2e7810dc/e4706/INSTAR_Cameras_with_Athom_Homey_05.avif 230w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/d1af7/INSTAR_Cameras_with_Athom_Homey_05.avif 460w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/7f308/INSTAR_Cameras_with_Athom_Homey_05.avif 920w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/e1c99/INSTAR_Cameras_with_Athom_Homey_05.avif 1380w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/2b305/INSTAR_Cameras_with_Athom_Homey_05.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a37ae4356f43067c3fb6e97a2e7810dc/a0b58/INSTAR_Cameras_with_Athom_Homey_05.webp 230w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/bc10c/INSTAR_Cameras_with_Athom_Homey_05.webp 460w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/966d8/INSTAR_Cameras_with_Athom_Homey_05.webp 920w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/445df/INSTAR_Cameras_with_Athom_Homey_05.webp 1380w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/293e0/INSTAR_Cameras_with_Athom_Homey_05.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a37ae4356f43067c3fb6e97a2e7810dc/81c8e/INSTAR_Cameras_with_Athom_Homey_05.png 230w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/08a84/INSTAR_Cameras_with_Athom_Homey_05.png 460w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/c0255/INSTAR_Cameras_with_Athom_Homey_05.png 920w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/b1001/INSTAR_Cameras_with_Athom_Homey_05.png 1380w", "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/aa440/INSTAR_Cameras_with_Athom_Homey_05.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a37ae4356f43067c3fb6e97a2e7810dc/c0255/INSTAR_Cameras_with_Athom_Homey_05.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`You can `}<em parentName="li">{`click and hold`}</em>{` the virtual button to rename it.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click on the cog wheel icon to enter the settings menu.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Add a name for your virtual button and add it to a zone.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d8d4ebba302799157e09196b59c1e91f/aa440/INSTAR_Cameras_with_Athom_Homey_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMElEQVQozwElAtr9AGt/t3B6wXNxwIl5zHJSx39GzmpYuDtaqUZQsFROt2BMwnhEup06oJZPzpdH1JVA1JdA1ppA15s+26RFzwDu7/Dy9Prs7vT///95b8xjP8ZWVLMoWKQzU6w+ULJNTbpbSsFpSMqEQsGEOcOGO8SKPMeMO8eLOMqWPcIAw9Lhw9Dmvcni0tjsYWi/UkW8TVmsJ2ChLVinMVSpO1CvS063WEy9e0nJgUXYgkTYhUPZh0PaiEHekUbSACdlnClhoixbpDFXqTZTrUZNtkFdpiNlnipfoy1apjFWqTpRr0ZPtXFKwXhFz3hFz3tE0X1E0n5C1ohGywAia5kjZZ4oYKEqW6QtV6c2Ua02YZ8fa5onZaBDc61Aaq0vVag1U6tmTL1vR8xwRstyRsx1Rs52RdF/SccAH3GVIGybI2edJ2GgKl6jLlaoLmWaHHGXIWqbLGuhLWWjKlukLFemXE+3Z0jHZ0nGaUfIbEfJbUbNd0rCABp2khtxlyBtmiFpnyRloSxdpClrlhh3lB1wlx9qmiJmnSdioSheolRRsl5Kwl5LwWBKw2NKxGRIyG9MvgAVfI8Xd5MUdJo3Yog7XYogZaQncJITfY8YdZQccZcfbZojaJ0kZJ5MVK5SSrxUTL1XS75ZS79aSsJkS7gAGIWNFn6PHX+YLG2LMWiOImufI3aNFYWNGn6SHHqVHnSWI2+aImqZWmuxVVa7Sky2T064UUy5VU+9cGO6YoX7JGEWhRAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8d4ebba302799157e09196b59c1e91f/e4706/INSTAR_Cameras_with_Athom_Homey_06.avif 230w", "/en/static/d8d4ebba302799157e09196b59c1e91f/d1af7/INSTAR_Cameras_with_Athom_Homey_06.avif 460w", "/en/static/d8d4ebba302799157e09196b59c1e91f/7f308/INSTAR_Cameras_with_Athom_Homey_06.avif 920w", "/en/static/d8d4ebba302799157e09196b59c1e91f/e1c99/INSTAR_Cameras_with_Athom_Homey_06.avif 1380w", "/en/static/d8d4ebba302799157e09196b59c1e91f/2b305/INSTAR_Cameras_with_Athom_Homey_06.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d8d4ebba302799157e09196b59c1e91f/a0b58/INSTAR_Cameras_with_Athom_Homey_06.webp 230w", "/en/static/d8d4ebba302799157e09196b59c1e91f/bc10c/INSTAR_Cameras_with_Athom_Homey_06.webp 460w", "/en/static/d8d4ebba302799157e09196b59c1e91f/966d8/INSTAR_Cameras_with_Athom_Homey_06.webp 920w", "/en/static/d8d4ebba302799157e09196b59c1e91f/445df/INSTAR_Cameras_with_Athom_Homey_06.webp 1380w", "/en/static/d8d4ebba302799157e09196b59c1e91f/293e0/INSTAR_Cameras_with_Athom_Homey_06.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d8d4ebba302799157e09196b59c1e91f/81c8e/INSTAR_Cameras_with_Athom_Homey_06.png 230w", "/en/static/d8d4ebba302799157e09196b59c1e91f/08a84/INSTAR_Cameras_with_Athom_Homey_06.png 460w", "/en/static/d8d4ebba302799157e09196b59c1e91f/c0255/INSTAR_Cameras_with_Athom_Homey_06.png 920w", "/en/static/d8d4ebba302799157e09196b59c1e91f/b1001/INSTAR_Cameras_with_Athom_Homey_06.png 1380w", "/en/static/d8d4ebba302799157e09196b59c1e91f/aa440/INSTAR_Cameras_with_Athom_Homey_06.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d8d4ebba302799157e09196b59c1e91f/c0255/INSTAR_Cameras_with_Athom_Homey_06.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Now we need to add some logic to made this button work. This can be done in the `}<strong parentName="li">{`Flows`}</strong>{` tab.`}</li>
    </ol>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Click on the `}<strong parentName="li">{`+`}</strong>{` icon in the top right to add a new flow.`}</li>
    </ol>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`In the `}<strong parentName="li">{`Create Flow`}</strong>{` menu click on `}<strong parentName="li">{`Add card`}</strong>{` in the `}<em parentName="li">{`When condition`}</em>{` to assign an event that should trigger your flow. Search for your virtual button under `}<strong parentName="li">{`Devices`}</strong>{` and select it. The button only has this one trigger - `}<em parentName="li">{`When button has bee pressed`}</em>{`. Select it and confirm.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d758f441df5a6734dc4037474473ce4/aa440/INSTAR_Cameras_with_Athom_Homey_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeElEQVQoz0WR2UtUARTGL2VjaWrpg0v+Fz0oEYSSM84IBYmZiGVYJG4RTmnpmDPO6jLjQlBUWCYZPSSpaSbhGmUyWWqFWSG9lBjmMvu9c38x9tCBHwc++D6+wxG6znzhft4SD3K/03NqmZ7cZQZyf1JeMUCyqZUDtW0k1LSSeK2V5Jp2YrV2hmem8W+u4XZtIvq9BHxuAmIQ7685hL7mZYY6fjB6a4XJO7+ZuL3Ku7tbXKrrJ0ZbT4LWTKLWQlKlhQOVVmJKDQwMjhAaSQYpKBMMBgkC8sY3hKuHP1BzaB5d6gLXUxaoT/1I48Gv5BQ8Yp/uOvFVJuKrTSRUmUiqshB1UU/v+GTIvh0kbyMhh5T1rwhXVE6qVbPUKD+gU85Rp5zHlrbIsQuPUdisxBjtRBtbiG5oIbbBQVhtI92DfbCxTtDlRna5kF1byF4J+ec8QpnqDRXKt1xSzlCZ4USrdFKXtoC6+CG7moxEmWz/MDay39TMTp2ZXuco+P1IoVNDDSXxf8Mi9STnM19RnPmaEtUbSlXTXE6f5WhJF2F2A5E2CxE2C5FWK9FWG4LeyJPZafAH8IsSAVFCFAOIMohrSwh5WaPka8Y5rZmgUD3FWc0UpRkzHCm/x452PXuazexuNrGnyczeJguC0UD3+ylkr4+AJCFKEpIoIoWe9GcJIVv9jJOa5+RphslXv6BAM8K59DFSym4idOgIdxhROBpQ2I1EOMwIJh19n2YJJbg8Prw+P16vD08AfKuLCEWFQxzXPCU7q4+crH5y1P2UZL/khL6TuE4LO9vqUbTrUbQZCG8zEHfDytjSAohBttwe3B4v7tD2y3hWPvMXbXICVUxr8G0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d758f441df5a6734dc4037474473ce4/e4706/INSTAR_Cameras_with_Athom_Homey_07.avif 230w", "/en/static/0d758f441df5a6734dc4037474473ce4/d1af7/INSTAR_Cameras_with_Athom_Homey_07.avif 460w", "/en/static/0d758f441df5a6734dc4037474473ce4/7f308/INSTAR_Cameras_with_Athom_Homey_07.avif 920w", "/en/static/0d758f441df5a6734dc4037474473ce4/e1c99/INSTAR_Cameras_with_Athom_Homey_07.avif 1380w", "/en/static/0d758f441df5a6734dc4037474473ce4/2b305/INSTAR_Cameras_with_Athom_Homey_07.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d758f441df5a6734dc4037474473ce4/a0b58/INSTAR_Cameras_with_Athom_Homey_07.webp 230w", "/en/static/0d758f441df5a6734dc4037474473ce4/bc10c/INSTAR_Cameras_with_Athom_Homey_07.webp 460w", "/en/static/0d758f441df5a6734dc4037474473ce4/966d8/INSTAR_Cameras_with_Athom_Homey_07.webp 920w", "/en/static/0d758f441df5a6734dc4037474473ce4/445df/INSTAR_Cameras_with_Athom_Homey_07.webp 1380w", "/en/static/0d758f441df5a6734dc4037474473ce4/293e0/INSTAR_Cameras_with_Athom_Homey_07.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d758f441df5a6734dc4037474473ce4/81c8e/INSTAR_Cameras_with_Athom_Homey_07.png 230w", "/en/static/0d758f441df5a6734dc4037474473ce4/08a84/INSTAR_Cameras_with_Athom_Homey_07.png 460w", "/en/static/0d758f441df5a6734dc4037474473ce4/c0255/INSTAR_Cameras_with_Athom_Homey_07.png 920w", "/en/static/0d758f441df5a6734dc4037474473ce4/b1001/INSTAR_Cameras_with_Athom_Homey_07.png 1380w", "/en/static/0d758f441df5a6734dc4037474473ce4/aa440/INSTAR_Cameras_with_Athom_Homey_07.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d758f441df5a6734dc4037474473ce4/c0255/INSTAR_Cameras_with_Athom_Homey_07.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Now swipe up the `}<strong parentName="li">{`Then`}</strong>{` section of our flow to define what Homey should do when the flow was triggered.`}</li>
    </ol>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Now click on `}<strong parentName="li">{`Add card`}</strong>{` to add the `}<strong parentName="li">{`Then`}</strong>{` condition for our flow.`}</li>
    </ol>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Scroll down to the `}<strong parentName="li">{`System/Logic`}</strong>{` and select it.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/acf42e00ffd0104e55aec73513e13d3a/aa440/INSTAR_Cameras_with_Athom_Homey_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByklEQVQoz23OP2gUQRQG8MEjd8RoCqMWklo7sbSyNUQsLCMICrYiIUQ2J/FcL1n2/mQTj5wgktMTPeEIBxbaCIJiERS1sBHEIsWp8Xb3dmd2Z97M7OzIaTAKefyaB9/He8jtdYFGSvJEQCJAACX9ru/9lJwpAUqA5ExLuNB4dPi6Mz6/ciS/Mp5fPmTUTxVMtPlj0/cDlaap1qkeDIl6Hg64kFKlUqUgldZ66v7jzHRxbK48ZlQOXivvnXFOGAb6+uWT/+27BtjGQfpbgAkQAhgDxizEKoqar180Xr5a33jfefthfePd04+fn715jojbo0E4SP/GcUS3umHPxa5HXI94fuh6KgyvdNon79ybbLYmm0/OPGhNNDuXGjXEgFMuKYgdccCAc5kkKk1UypPB2xMP19B8IVu0MqaVvWmhG5Wj5iyilFHG/0P6jIGQyR9MSK312dYaWjRHq6X9ZXu0ZGftpeMlY5dyjPtxTCkD4BK4jEFokZxuN1DZ3OdYw1VrpLKYKVeOOXO7lBkJ/l2jmGmtL9ftkcL00KqdW14YdhbQUunczHlEB1c4pbAD+5TBXxFlKkln23cPVPN76sVc7VbutjlUsy9Wr/4CWSyl8EXXdQUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/acf42e00ffd0104e55aec73513e13d3a/e4706/INSTAR_Cameras_with_Athom_Homey_08.avif 230w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/d1af7/INSTAR_Cameras_with_Athom_Homey_08.avif 460w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/7f308/INSTAR_Cameras_with_Athom_Homey_08.avif 920w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/e1c99/INSTAR_Cameras_with_Athom_Homey_08.avif 1380w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/2b305/INSTAR_Cameras_with_Athom_Homey_08.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/acf42e00ffd0104e55aec73513e13d3a/a0b58/INSTAR_Cameras_with_Athom_Homey_08.webp 230w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/bc10c/INSTAR_Cameras_with_Athom_Homey_08.webp 460w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/966d8/INSTAR_Cameras_with_Athom_Homey_08.webp 920w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/445df/INSTAR_Cameras_with_Athom_Homey_08.webp 1380w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/293e0/INSTAR_Cameras_with_Athom_Homey_08.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/acf42e00ffd0104e55aec73513e13d3a/81c8e/INSTAR_Cameras_with_Athom_Homey_08.png 230w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/08a84/INSTAR_Cameras_with_Athom_Homey_08.png 460w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/c0255/INSTAR_Cameras_with_Athom_Homey_08.png 920w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/b1001/INSTAR_Cameras_with_Athom_Homey_08.png 1380w", "/en/static/acf42e00ffd0104e55aec73513e13d3a/aa440/INSTAR_Cameras_with_Athom_Homey_08.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/acf42e00ffd0104e55aec73513e13d3a/c0255/INSTAR_Cameras_with_Athom_Homey_08.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Select that you want to `}<strong parentName="li">{`Make a web request`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`GET Method`}</strong>{` and add the `}<a parentName="li" {...{
          "href": "/en/Advanced_User/CGI_Commands/"
        }}>{`CGI command`}</a>{` you want to use. CGI commands allow you to control everything your camera is able to do - `}<em parentName="li">{`go to a preset position`}</em>{`, `}<em parentName="li">{`activate/deactivate the alarm`}</em>{`, `}<em parentName="li">{`trigger an alarm`}</em>{`, etc. Simply choose the corresponding `}<a parentName="li" {...{
          "href": "/en/Advanced_User/CGI_Commands/"
        }}>{`CGI command from our documentation`}</a>{`. The example below will send your pan&tilt camera to preset position 1 - you only have to replace the IP address with the IP of your camera and add your personal camera login in the end of the command:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.116:80/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`After you confirmed your edit click `}<strong parentName="li">{`Test`}</strong>{` to verify that everything is working - make sure that you actually saved a position on preset #1 and should see that your camera starts moving towards it. Now you can click on save to exit the setup window.`}</li>
    </ol>
    <h2 {...{
      "id": "notifying-homey-when-the-camera-alarm-is-triggered-alarm-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#notifying-homey-when-the-camera-alarm-is-triggered-alarm-server",
        "aria-label": "notifying homey when the camera alarm is triggered alarm server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Notifying Homey when the Camera Alarm is triggered (Alarm Server)`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please note`}</strong>{` that there is a new `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
        }}>{`Alarm Server API for WQHD 2K+ cameras`}</a>{`! The individual steps below are still valid. Only the query is now called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&trigger`}</code>{` instead of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&active`}</code>{` and the numerical values have changed and now allow the evaluation of multiple, simultaneous alarm triggers!`}</p>
    </blockquote>
    <p>{`Homey already supports the use of Webhooks out of the box. This means that it can provide an URL that a third-party product can contact to trigger an event. So we are able to use our camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarm-Server`}</a>{` to contact our Homey when an alarm was triggered. But there is one problem - Homey only supports HTTPS Webhooks and INSTAR cameras currently only support HTTP (this might have been already fixed - we are just waiting for an firmware update). But as an alternative you can use a `}<a parentName="p" {...{
        "href": "https://apps.athom.com/app/com.internet"
      }}>{`webhook app that allows local access via HTTP`}</a>{`. Solving our problem.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1fe08dd6cbcff7a582384415d469ec8f/aa440/INSTAR_Cameras_with_Athom_Homey_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVQoz1WOQWsTQRSAt2fZ7CZrN7M7uzPvzc5mk1ZLC4K/QuxR8GJag/YiKPgj/Af+AFEI1qsHi2i1oSdBRTBIPHiykBhi27RJt/tks6XUj3ca3ve+MRQE3Pd0JJJYeswNOVtogI4V91yfuS6bj13nRsW6fWt14/7GWrN5d329tda802w9bt00dMgF8xZifTVJAsbCsoPLdbh+7bJlm2bJNEslyzbm5rZetWlyOP0zGPcHx8Ph0ejo9+ctY6WeoM/R96Xn5eO6SQxCa5/lVKtV5nmWeen5i803X/5++r6XEVF2SkQ06hmLi40gDIMw5EHgc858X2MAMkcIEeYIzubvPXzirD579OAp/eymuUrZqGcoBFFsBTmc8xgDADiXhRBOpfyy3d7ufOt+7dLBQUbZWVlhkZGzSH6hpkJELGQhhJTSsqzdznsiStNZ9fzbkUJAvJiqRQIvvACAbdu7H97l8sn/ck1HgApmFP2alqjUmSwlAJRtu7PzlojG6XSfsuFpdpjRybBnrCxd0Tpq1Os6iiKlVISNGJRSRRykRESnXHn9cfOYpv3J/iCdDKaTvTH1f/34B3tDxxydF3ohAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1fe08dd6cbcff7a582384415d469ec8f/e4706/INSTAR_Cameras_with_Athom_Homey_09.avif 230w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/d1af7/INSTAR_Cameras_with_Athom_Homey_09.avif 460w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/7f308/INSTAR_Cameras_with_Athom_Homey_09.avif 920w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/e1c99/INSTAR_Cameras_with_Athom_Homey_09.avif 1380w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/2b305/INSTAR_Cameras_with_Athom_Homey_09.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1fe08dd6cbcff7a582384415d469ec8f/a0b58/INSTAR_Cameras_with_Athom_Homey_09.webp 230w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/bc10c/INSTAR_Cameras_with_Athom_Homey_09.webp 460w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/966d8/INSTAR_Cameras_with_Athom_Homey_09.webp 920w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/445df/INSTAR_Cameras_with_Athom_Homey_09.webp 1380w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/293e0/INSTAR_Cameras_with_Athom_Homey_09.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1fe08dd6cbcff7a582384415d469ec8f/81c8e/INSTAR_Cameras_with_Athom_Homey_09.png 230w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/08a84/INSTAR_Cameras_with_Athom_Homey_09.png 460w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/c0255/INSTAR_Cameras_with_Athom_Homey_09.png 920w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/b1001/INSTAR_Cameras_with_Athom_Homey_09.png 1380w", "/en/static/1fe08dd6cbcff7a582384415d469ec8f/aa440/INSTAR_Cameras_with_Athom_Homey_09.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1fe08dd6cbcff7a582384415d469ec8f/c0255/INSTAR_Cameras_with_Athom_Homey_09.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Switch to the `}<strong parentName="li">{`More`}</strong>{` tab and and open the `}<strong parentName="li">{`App`}</strong>{` menu.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on the `}<strong parentName="li">{`+`}</strong>{` symbol in the top right to add a new app.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Type `}<strong parentName="li">{`http request`}</strong>{` into the search field and install the `}<a parentName="li" {...{
          "href": "https://apps.athom.com/app/com.internet"
        }}>{`HTTP Request App`}</a>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6341e057d1f7cd65e808025a164b767/aa440/INSTAR_Cameras_with_Athom_Homey_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMElEQVQozwElAtr9AEtlrUtaslhWuGVUwHZQw6BBoJlOxZZJ1ZVB1JdA1Zk/15xA2Js42NW81/P26/r39v37+v369/z9//nq1ABegbhXdLtecL9qbcV2a8uBa9SIUMaCOMKFO8SJPMaLO8eMO8iLL8fQvc78//n+/v/+///+/v3+////8d0ATHquSXGySWqzT2W3XGO+aWLEelLHf0TXgkXXhEPZh0PaikTchzrc07nS+f30//////////79//////HdACZlnSdfoitbpS9WqDZSrUJPs2dMvHdEz3dFz3pE0X1E0oBF030608q73P3/+vz7/vz8/fz7+/z///zv3AAja5kkZp8oYaErXKQuV6cyU6paT7ZvR8tvR8txRsx0Rs14R890PM7Ar8v9//n+/f/+/v7+/fz+///+8d0AH3GVIGybI2edJ2GhK12kK1ilT1KvZkjGZUjGaUjIa0fJb0nLaj7KwrXP+vz2/fz+/f39/fz7/f///e/cABp2khxxlx9tmiNonSdjoCdgoUhWq11KwVxLwWBJw2JKxGZLxmFAxc7E3/H07vn5+/7///39/P3///3w3QAVfI8Wd5MacpYfbpkiaZ0iZZ5CWqdSSrxUTLxWS71ZS79dTcFUP77Nt87x7Ob27/H+9/j99fX9+f796dYAGYWNFX6QHnyVHXWWInGaIGuZSmmnXV69SUy1Tk64UU25U026YFfAwMPO+vPu/PX2/fb2/fX0/fn9/enVTVVVT/hEjFkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6341e057d1f7cd65e808025a164b767/e4706/INSTAR_Cameras_with_Athom_Homey_10.avif 230w", "/en/static/c6341e057d1f7cd65e808025a164b767/d1af7/INSTAR_Cameras_with_Athom_Homey_10.avif 460w", "/en/static/c6341e057d1f7cd65e808025a164b767/7f308/INSTAR_Cameras_with_Athom_Homey_10.avif 920w", "/en/static/c6341e057d1f7cd65e808025a164b767/e1c99/INSTAR_Cameras_with_Athom_Homey_10.avif 1380w", "/en/static/c6341e057d1f7cd65e808025a164b767/2b305/INSTAR_Cameras_with_Athom_Homey_10.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6341e057d1f7cd65e808025a164b767/a0b58/INSTAR_Cameras_with_Athom_Homey_10.webp 230w", "/en/static/c6341e057d1f7cd65e808025a164b767/bc10c/INSTAR_Cameras_with_Athom_Homey_10.webp 460w", "/en/static/c6341e057d1f7cd65e808025a164b767/966d8/INSTAR_Cameras_with_Athom_Homey_10.webp 920w", "/en/static/c6341e057d1f7cd65e808025a164b767/445df/INSTAR_Cameras_with_Athom_Homey_10.webp 1380w", "/en/static/c6341e057d1f7cd65e808025a164b767/293e0/INSTAR_Cameras_with_Athom_Homey_10.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6341e057d1f7cd65e808025a164b767/81c8e/INSTAR_Cameras_with_Athom_Homey_10.png 230w", "/en/static/c6341e057d1f7cd65e808025a164b767/08a84/INSTAR_Cameras_with_Athom_Homey_10.png 460w", "/en/static/c6341e057d1f7cd65e808025a164b767/c0255/INSTAR_Cameras_with_Athom_Homey_10.png 920w", "/en/static/c6341e057d1f7cd65e808025a164b767/b1001/INSTAR_Cameras_with_Athom_Homey_10.png 1380w", "/en/static/c6341e057d1f7cd65e808025a164b767/aa440/INSTAR_Cameras_with_Athom_Homey_10.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6341e057d1f7cd65e808025a164b767/c0255/INSTAR_Cameras_with_Athom_Homey_10.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Now back in the `}<strong parentName="li">{`Flows`}</strong>{` tab click on the `}<strong parentName="li">{`+`}</strong>{` icon in the top right to add a new flow.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`In the `}<strong parentName="li">{`Create Flow`}</strong>{` menu click on `}<strong parentName="li">{`Add card`}</strong>{` in the `}<em parentName="li">{`When condition`}</em>{` to assign an event that should trigger your flow.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Scroll down to the `}<strong parentName="li">{`Apps`}</strong>{` section and select `}<strong parentName="li">{`HTTP request flow card`}</strong>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/aa440/INSTAR_Cameras_with_Athom_Homey_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHklEQVQoz2N4++DO6yePfnx48/frp9+fPv77+eHs4hvzQm8sjLi9JOze8vCHK8Ifbg595lu5WKp1gnT1RMnqCdJVE0Qqp9nWNzK8vH71x5ev/3/+/Pf9x99v3///+319670N7Te2TX54YNbLI3PfHpn/9vTUj15Vs/hK6yVL2qVK26WL24QKui3Kyhg+vnv7/efvn7///Pz95/vPP////9u74EGx0cV626t1llcaLK82WlzrNbtjlTtVoLZBoqJNorJNsqJVsLTLorqM4fv3H9++/fr27ee3bz+/fPnx9/+fXQtuF9idqfK6WON+qc79cr37lS6nm4blM9g7OwVa+vhaegWaezmbJuu31jB8+/bt2/df377//Pb955evP/79/7N14c1Uu+OFnmcK3c6UuJ0tdTvX4HRZv3IqW3cLX2sXb1sXf2snR3O/flslw7dv3yE64Zo3Lr4ebX8ow+t4pseJbPeTOR6nypzPa1dPZulr5uns4O7s4OvoYG3v1emsQNf8//+ftYuvBjnujfc9FO91OMnraLLX0Vy30xr1E5kmNXH1tHH2tHF3tzJ3dWv3lGFq/rtqySVPxy0RvrsjvHZHe+2J8d6b7HxQobabYUo9e38rW38LZ18LQ2+nak8Rw7evX8Gh9ePbtx9fvnz/+/f3lnUXIoM3BntvCPXeGO61KdxzU2H4HsdJ07nntLBMbGCf3MQ+sZF1cofz3HoADOBOG6vFsRkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/e4706/INSTAR_Cameras_with_Athom_Homey_11.avif 230w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/d1af7/INSTAR_Cameras_with_Athom_Homey_11.avif 460w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/7f308/INSTAR_Cameras_with_Athom_Homey_11.avif 920w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/e1c99/INSTAR_Cameras_with_Athom_Homey_11.avif 1380w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/2b305/INSTAR_Cameras_with_Athom_Homey_11.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/a0b58/INSTAR_Cameras_with_Athom_Homey_11.webp 230w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/bc10c/INSTAR_Cameras_with_Athom_Homey_11.webp 460w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/966d8/INSTAR_Cameras_with_Athom_Homey_11.webp 920w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/445df/INSTAR_Cameras_with_Athom_Homey_11.webp 1380w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/293e0/INSTAR_Cameras_with_Athom_Homey_11.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/81c8e/INSTAR_Cameras_with_Athom_Homey_11.png 230w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/08a84/INSTAR_Cameras_with_Athom_Homey_11.png 460w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/c0255/INSTAR_Cameras_with_Athom_Homey_11.png 920w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/b1001/INSTAR_Cameras_with_Athom_Homey_11.png 1380w", "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/aa440/INSTAR_Cameras_with_Athom_Homey_11.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d05ea00c18c5d5b20e8b3ed1b7723fec/c0255/INSTAR_Cameras_with_Athom_Homey_11.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Incomming GET`}</strong>{` to create a webhook for your cameras `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
        }}>{`Alarm-Server`}</a>{`. And select a name that will be the URL for the webhook - in the example I choose `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`OfficeCam_Alarm`}</code></li>
    </ol>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Now swipe up the `}<strong parentName="li">{`Then`}</strong>{` section of our flow to define what Homey should do when the flow was triggered.`}</li>
    </ol>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Now click on `}<strong parentName="li">{`Add card`}</strong>{` to add the `}<strong parentName="li">{`Then`}</strong>{` condition for our flow.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/aa440/INSTAR_Cameras_with_Athom_Homey_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABiUlEQVQoz1XNzWoTURjG8UPdSC/CjSsLvQ9xIVq69AZEbCmIoIIh1TiiLkKlaIygXfgRWygEUbr1FhS7rZpOk5PJycycOe95z+crYhmSh9/24c+m45MiF96iM9oZbTRMhr/Hg1+hKoM6ZfOJ4H9kmVNw0dvgjY+k+Q82PU6JKIYYI8VIRFQUYjzkRlZWwX9eQTZMxYhDXkBe6KK0GDw/ZCLL/j0o1jxKbRygrSm0FU9lNpGZqMTUSGkxmPQ7S08GND+LUgGCNjNQV7lGdM45Y5w1NhDynyxLjyJg1LpmS6FmnhVgdCH5cnC1+2G121t58X618/FKZ3+9u83GxwNdlB50zZRiNisVUqTLL9+yG40zGy223lq42WLXH5/fuMU4H+VFGYlCPGW0VDBXDs4/+nqw9m7vzl7/7m7/3m6/+fnbk09vWDoZOeO8DzUD5VwZkHy81uude/Z8eevVUruz3O5c2Nq52E6YUgo0zpECAGtSafLh0s5rdr+x+DA5u5ksNpOFxtOlB7f/Ao061AnF9lF1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/e4706/INSTAR_Cameras_with_Athom_Homey_12.avif 230w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/d1af7/INSTAR_Cameras_with_Athom_Homey_12.avif 460w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/7f308/INSTAR_Cameras_with_Athom_Homey_12.avif 920w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/e1c99/INSTAR_Cameras_with_Athom_Homey_12.avif 1380w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/2b305/INSTAR_Cameras_with_Athom_Homey_12.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/a0b58/INSTAR_Cameras_with_Athom_Homey_12.webp 230w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/bc10c/INSTAR_Cameras_with_Athom_Homey_12.webp 460w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/966d8/INSTAR_Cameras_with_Athom_Homey_12.webp 920w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/445df/INSTAR_Cameras_with_Athom_Homey_12.webp 1380w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/293e0/INSTAR_Cameras_with_Athom_Homey_12.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/81c8e/INSTAR_Cameras_with_Athom_Homey_12.png 230w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/08a84/INSTAR_Cameras_with_Athom_Homey_12.png 460w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/c0255/INSTAR_Cameras_with_Athom_Homey_12.png 920w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/b1001/INSTAR_Cameras_with_Athom_Homey_12.png 1380w", "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/aa440/INSTAR_Cameras_with_Athom_Homey_12.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99beb3bdb85d29fd69f1f0cfdddfea50/c0255/INSTAR_Cameras_with_Athom_Homey_12.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Now, for testing, I want to set up my Homey to read a sentence out load when my camera triggers an alarm. For this scroll down to the `}<strong parentName="li">{`System/Speech`}</strong>{` and select it.`}</li>
    </ol>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Say something`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Type a sentence that you want to be read when the camera is triggered.`}</li>
    </ol>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Now set up your cameras `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
        }}>{`Alarm-Server`}</a>{` to contact your Webhook that you created earlier - e.g. if your Homeys IP address is `}<em parentName="li">{`192.168.2.53`}</em>{` and you named your webhook `}<em parentName="li">{`OfficeCam`}{`_`}{`Alarm`}</em>{` the URL should look like this: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.2.53/api/app/com.internet/whitelist/OfficeCam_Alarm`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "840px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0915ff265160a9a3a138cb42c824898e/1e088/INSTAR_Cameras_with_Athom_Homey_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC4klEQVQ4y21SS08TURi9rkQ3LjESiEb0J7g3bowYFkpETFwY0QRJoI/pdB4G2gIRaAuGRGBhQ4HER1RQEmOMKwwK8kaCFSgGEwFpSx/M3Hndzly9M5WHevIlc7/FmXO+833ARdNu2kWxdVW+0M36UGV96EZdqNITvuX9u277wlWNvXea+hlvi8/n8fp8gKJcNGV33L3nebXuHYp5hjbp5xuOp2vO/eV4skY/W296vdn4JuWs76h3UyzHAzfL2RzOtrbg3Gq6f+TnwHhsYCL2cjI+OBEfmIi9GI8N5lry6Bten97QOivKL+UdOlpUBCiaqbHZg8HgjwR8H0l9iqZHl1Lj0czkN2Fif02tim/nEgtbOHDh/FkAjuTnA5ebqbU7Wv0BpMqGJmVVaCAZ56BjQ8M4+6fFiooQxtfLyg4CUHyqeJcsy7IIJU1DumEYGBsGliQZQklRVA2hXGkqxvjKtQoAQEFhIWBYttbubPUHFJlA1/VdHUURRVGSJAihKIqCIGQyGYzx5ZKSAwCcPFYAeJ6rsRFlSZJEUYQQynLOtqqqsiwjIqghhKwWY+wsv3om7/C54tOA5wi5xR80TRIFRVF2yIqi6LqOEMpmswoBIV8sLQUAHD9xAnAcWVVrICjLsuUQQmhOjZEJXdezBOSTEpS0jLu7u6qrq2maJrZtDmeLP2j93nJokf+FqCCo4b6+Xp7nGxoagJthc2mbsnsz25lWywFJEGKMe3p6GIbxeDzkSGqtwEzb2ezuVre3t+PxeDKZTKfTGROpVErX9XA4zLKs1+sFNMParFUpZFmWuGVbEIREIrFlIrm19R+ym2HtDqc/EFBV1QrWmtkwjL22yY2YiVi2c2Se4yiKam9v33se5DJN8ZnvUmg4/nBMmH43m+l4sNF6Xx0Z6338iGUYQuY4jqbp5ubmSCSyvLy8ZCJqYmUl+vnL4thMZGx2cW5q7uvH0YWRDyvz851dXTzP/yb/AhHEBtc47BRVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0915ff265160a9a3a138cb42c824898e/e4706/INSTAR_Cameras_with_Athom_Homey_13.avif 230w", "/en/static/0915ff265160a9a3a138cb42c824898e/d1af7/INSTAR_Cameras_with_Athom_Homey_13.avif 460w", "/en/static/0915ff265160a9a3a138cb42c824898e/32fee/INSTAR_Cameras_with_Athom_Homey_13.avif 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0915ff265160a9a3a138cb42c824898e/a0b58/INSTAR_Cameras_with_Athom_Homey_13.webp 230w", "/en/static/0915ff265160a9a3a138cb42c824898e/bc10c/INSTAR_Cameras_with_Athom_Homey_13.webp 460w", "/en/static/0915ff265160a9a3a138cb42c824898e/ed60d/INSTAR_Cameras_with_Athom_Homey_13.webp 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0915ff265160a9a3a138cb42c824898e/81c8e/INSTAR_Cameras_with_Athom_Homey_13.png 230w", "/en/static/0915ff265160a9a3a138cb42c824898e/08a84/INSTAR_Cameras_with_Athom_Homey_13.png 460w", "/en/static/0915ff265160a9a3a138cb42c824898e/1e088/INSTAR_Cameras_with_Athom_Homey_13.png 840w"],
              "sizes": "(max-width: 840px) 100vw, 840px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0915ff265160a9a3a138cb42c824898e/1e088/INSTAR_Cameras_with_Athom_Homey_13.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* http://192.168.2.53/api/app/com.internet/OfficeCam_Alarm */}
    <h2 {...{
      "id": "using-zigbeez-wave-sensors-to-control-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-zigbeez-wave-sensors-to-control-your-camera",
        "aria-label": "using zigbeez wave sensors to control your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Zigbee/Z-Wave Sensors to control your Camera`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.athom.com/talks-with-homey/"
      }}>{`Homey works with many different sensors`}</a>{` that can be used to control your INSTAR IP camera. As an example we will connect an `}<a parentName="p" {...{
        "href": "https://www.mi.com/global/mi-smart-sensor-set/"
      }}>{`XiaoMi Occupancy Sensor and a Smartbutton`}</a>{` to our Homey - voiding the need to use the XiaoMi Gateway and cloud service - and write a flow that will send `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`a CGI command`}</a>{` of our choosing to our camera - e.g. go to a preset position, activate video recording, etc. To add a sensor we first have to install the corresponding app from the Homey App store:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/aa440/INSTAR_Cameras_with_Athom_Homey_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzUlEQVQoz02Ny2sTURTGxy59ZJJMMrkz99y5z2QyjRQLgv+Ei3aluLKJkYJuuxHcu1VwJXbThYvSgispFCqt9bFx4SILNeDGFxpTqw1JJpMjE+Pjx+HA+fi+81lKAPU9o4OwzD3iMkpmI2FCA57rE9clxch1zuczly4uXFtebiwtXWnUm4365frVleaCZYAGxKsZMxeGQAjkHHkmEufOFu3cKdvOZOxsNndsZmZrcyMZjI46B8NOd/Ct2z/sf361bc1XQ+lT6fvc89JxS2GZM608klIqlQghx0+c3H24hp9aPz6+HiBiMkRE/N62ZmsRMAaMUQCfUs/3taABYyxIFwAEDDKOu3f7Oj660VtZ7F5YHK0/QMRx942lpAiCP0YASqmR9K9AKWUAmWxud/M+vn95tLXRv7c6brWmzUoKPoFNAICKAiFS8fcLHjC74D2/28THN/s7t34i9noHafiwbWklhZT/u6smUEqJCZxzIXi+UNxfv4Pt7WH7Sbfz5euHd9PmitFC/rNOwlwpLdOPknMhpcznc8+e7iFiPIqTJMFxMg3Pz502RkfVqtFaK6WUrFVk2ejpKaXRuuA4L/Z3EHEUDxHHcRIPEIedt78AAtzEGL2qC/MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7de1ccded3c6a501f90732c0fd2e29bb/e4706/INSTAR_Cameras_with_Athom_Homey_14.avif 230w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/d1af7/INSTAR_Cameras_with_Athom_Homey_14.avif 460w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/7f308/INSTAR_Cameras_with_Athom_Homey_14.avif 920w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/e1c99/INSTAR_Cameras_with_Athom_Homey_14.avif 1380w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/2b305/INSTAR_Cameras_with_Athom_Homey_14.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7de1ccded3c6a501f90732c0fd2e29bb/a0b58/INSTAR_Cameras_with_Athom_Homey_14.webp 230w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/bc10c/INSTAR_Cameras_with_Athom_Homey_14.webp 460w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/966d8/INSTAR_Cameras_with_Athom_Homey_14.webp 920w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/445df/INSTAR_Cameras_with_Athom_Homey_14.webp 1380w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/293e0/INSTAR_Cameras_with_Athom_Homey_14.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7de1ccded3c6a501f90732c0fd2e29bb/81c8e/INSTAR_Cameras_with_Athom_Homey_14.png 230w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/08a84/INSTAR_Cameras_with_Athom_Homey_14.png 460w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/c0255/INSTAR_Cameras_with_Athom_Homey_14.png 920w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/b1001/INSTAR_Cameras_with_Athom_Homey_14.png 1380w", "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/aa440/INSTAR_Cameras_with_Athom_Homey_14.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7de1ccded3c6a501f90732c0fd2e29bb/c0255/INSTAR_Cameras_with_Athom_Homey_14.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Start by going to the `}<strong parentName="li">{`More`}</strong>{` tab and select `}<strong parentName="li">{`Apps`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on the `}<strong parentName="li">{`+`}</strong>{` icon in the top right to add a new app.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Type in the name of the manufacturer of your sensor - in our case `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`xiaomi`}</code>{` - and install the corresponding app - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`XiaoMi/Aqara ZigBee`}</code>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75690fd2789ae6a1379a7b704e8df8e9/aa440/INSTAR_Cameras_with_Athom_Homey_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMElEQVQozwElAtr9AGt/uHF6wXRxwIZ4zYpqxsRts2hEcwwPDwwKDwsJDg0JDw0IDw4JD1JOS3Bxc15cX25rb2JeYmpoc4BuXgDt7/Hx8/rr7vP5+P7o6fP///+roLK2trehoqOpqaqjo6WhoaKhoaK3tLD////b29z////g3+D9///s3ssA1d7n19zn0dnn3d/sz9Dk6Of0u7TH////8fLy9/f37+/w8fHx8fDxrquo3Nvburu72trav76+2N3i08e0AOLn6+vm4N3h5/Dr6dTT2P///4uHlWFhYV9fX2NiYl1hYnFdXXdUVKytqf///93d3v///+Tm5v/4/evWwwDn7e/o8Pjh6fDu8vrd5O////9panYAAAAODg8LCwsICwwZDQ0UAACFhoLn5+e6u7za2dm+w8Ldur7Tr5wA0d7j1ePsydbh3OTvx9Lg8vb/ZWp4DQ4OGRsbFxkZGBgZFxscCAwOn5yY////1NXV/Pz829ra+v7/6NvIAPH19e/0+Ozy9fX4++Xr8f///21zeg0MDBkaGxgYGRcYGBoaGw4NDY+Nie/v772+v+Dg4MXFxdze49XItAA8kJ5Aj6Y/iKVIiatFgKlVibstR10BCAcHDxIHDhAGDhEJDxQAAAWbmpb////S09T6+vrZ2tn2+f7n2scAEYGKDXqMGXiTFW+SGWqUHWqgHD9UAgkGAgwNAgoMAwoMBQwPAAABkZCL+Pj3x8nJ6enpzs7M6Ovv3tG9ehxG7hXXIUQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75690fd2789ae6a1379a7b704e8df8e9/e4706/INSTAR_Cameras_with_Athom_Homey_15.avif 230w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/d1af7/INSTAR_Cameras_with_Athom_Homey_15.avif 460w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/7f308/INSTAR_Cameras_with_Athom_Homey_15.avif 920w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/e1c99/INSTAR_Cameras_with_Athom_Homey_15.avif 1380w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/2b305/INSTAR_Cameras_with_Athom_Homey_15.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75690fd2789ae6a1379a7b704e8df8e9/a0b58/INSTAR_Cameras_with_Athom_Homey_15.webp 230w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/bc10c/INSTAR_Cameras_with_Athom_Homey_15.webp 460w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/966d8/INSTAR_Cameras_with_Athom_Homey_15.webp 920w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/445df/INSTAR_Cameras_with_Athom_Homey_15.webp 1380w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/293e0/INSTAR_Cameras_with_Athom_Homey_15.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75690fd2789ae6a1379a7b704e8df8e9/81c8e/INSTAR_Cameras_with_Athom_Homey_15.png 230w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/08a84/INSTAR_Cameras_with_Athom_Homey_15.png 460w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/c0255/INSTAR_Cameras_with_Athom_Homey_15.png 920w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/b1001/INSTAR_Cameras_with_Athom_Homey_15.png 1380w", "/en/static/75690fd2789ae6a1379a7b704e8df8e9/aa440/INSTAR_Cameras_with_Athom_Homey_15.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75690fd2789ae6a1379a7b704e8df8e9/c0255/INSTAR_Cameras_with_Athom_Homey_15.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Now switch to the `}<strong parentName="li">{`Devices`}</strong>{` tab and click on the `}<strong parentName="li">{`+`}</strong>{` icon in the top right to add a new device.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`The following menu should now show the app you just installed. Select it to continue.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Search for the sensor you want to add, e.g. a occupancy sensor or smart switch.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/42b7330bb088d13a1726cc982dcb51ed/aa440/INSTAR_Cameras_with_Athom_Homey_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGklEQVQoz2MQFuDl5eHk4+Xk5+Xi4+Xi5+XS01KLio4LC43w8fTz8wrw9w7y8/D2jev3StwWGL4lIWx3duixDrft6/0nM0hIiIuJS4iJi4tLSEhKSnLz8CQnxf3////Pnz//////9+/f////P398V9q7Ibh0d0TB7uTcgwXZxyoyrkzO3sEgJioiLCwCAaKiopycnClgzd9//Pzx4+fv37/Bmt/n9qz2rdjiV7glKHdHbPaetPRTrdnrGaSlJCQkJCXA1kpJSfHx8aUmx//////lu/cPHj368OEDRHPh5NW+DZv9KjYFlGwNKdgemXO4Kn8lg4S4qJiYmKgoiBQXl+Dm5kpJioc4+9+/f1+/fv33//+Xjx8yJi13b17vVbveq3yjX/Fmn7w92QULGYQE+fn4+PgF+AUFBQUEBJmZmZMSYv/////r16+/f/9CfP75w/usmcucO9a4Na3xqFvvW7XBs3RXaslchnlzZ69Zu3bhwoXz5s1ftXLV0qXLZs2Y+uPHjw8fPnz8+PHTp08/fv58//p1xuwlDr0rXdtXuTWv8apf6161LalyNsP7d2/+////999fSMD+//9/z+4d/2EAIvjt48eomfP0uxeYty0yaVhsWbPErGxtWPkUht7VtZN2N0/aDkY7m/u31fVNbblz5/6Nmzdv3rp14+bNO3fvXjpz1rStg7uuV6pmkkzZFIWiaVJZ8xzzOgC5Zya1NXKWQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/42b7330bb088d13a1726cc982dcb51ed/e4706/INSTAR_Cameras_with_Athom_Homey_16.avif 230w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/d1af7/INSTAR_Cameras_with_Athom_Homey_16.avif 460w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/7f308/INSTAR_Cameras_with_Athom_Homey_16.avif 920w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/e1c99/INSTAR_Cameras_with_Athom_Homey_16.avif 1380w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/2b305/INSTAR_Cameras_with_Athom_Homey_16.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/42b7330bb088d13a1726cc982dcb51ed/a0b58/INSTAR_Cameras_with_Athom_Homey_16.webp 230w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/bc10c/INSTAR_Cameras_with_Athom_Homey_16.webp 460w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/966d8/INSTAR_Cameras_with_Athom_Homey_16.webp 920w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/445df/INSTAR_Cameras_with_Athom_Homey_16.webp 1380w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/293e0/INSTAR_Cameras_with_Athom_Homey_16.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/42b7330bb088d13a1726cc982dcb51ed/81c8e/INSTAR_Cameras_with_Athom_Homey_16.png 230w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/08a84/INSTAR_Cameras_with_Athom_Homey_16.png 460w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/c0255/INSTAR_Cameras_with_Athom_Homey_16.png 920w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/b1001/INSTAR_Cameras_with_Athom_Homey_16.png 1380w", "/en/static/42b7330bb088d13a1726cc982dcb51ed/aa440/INSTAR_Cameras_with_Athom_Homey_16.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/42b7330bb088d13a1726cc982dcb51ed/c0255/INSTAR_Cameras_with_Athom_Homey_16.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Install`}</strong>{` to add the sensor to your devices list.`}</li>
    </ol>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Follow the instruction on how to switch your sensor into pairing mode and wait for Homey to connect to it.`}</li>
    </ol>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Now we can start writing a small script to connect to sensor with our camera inside the `}<strong parentName="li">{`Flows`}</strong>{` tab.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/aa440/INSTAR_Cameras_with_Athom_Homey_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVQoz23R30tTYRjA8fMfdRm1Ucs8c+5sIxdEFwMNLCpIKCIhipREyyIp0KIuI+hKu4hyYJoOz6q5ljrz5Fq/TJ37vZ1z3h/nvO/7xOn042Yvn9vvw8PzSveV5fG27MTBtQe+j4982sO9n5J31yu1n8Q0gFuCUcGoRczmprbzNV/Z+sH0BjMaNiJ0My0NeeaH9iVueBZvedXb3uToHjV+ZxVxzBnYNmfMwQXgfHrn23e9XAFKBUbCBihr0oA/Oyhr19u14cMbN+XcyP4vUyPvm6gCAIILEOBChVyhWNINkwM4AwFYIy9d8qf65cxl+cOV9pVr8upVz9rk6FJdL3IO4v/jxGgYJkGIIEwRwsgCXNKkc4E3fR2p8/6li/50fyBzwZt5MZGt6yWMLQHAhXARs+lkrn9xbzBxMqCeDiTPdrzt63x35kDq+Zha3f5cLRTBokB/I5jotRZxLDQbU+a7gwsngolTymL3IfXZ2EJ1K1fe3hWE/IFR67grNB0NzRxTXh1XZmPh10d9c0/vqYZeMrHFBTAuXK3X7umd6Qy/jESmj0Ti0XA8Fp17/CRVR1VG/36Uw8ZGzbmWy0SIAi6u/wLEgbTovzqXfAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b382688b2dee1f3875fabdad8b6c4fb2/e4706/INSTAR_Cameras_with_Athom_Homey_17.avif 230w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/d1af7/INSTAR_Cameras_with_Athom_Homey_17.avif 460w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/7f308/INSTAR_Cameras_with_Athom_Homey_17.avif 920w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/e1c99/INSTAR_Cameras_with_Athom_Homey_17.avif 1380w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/2b305/INSTAR_Cameras_with_Athom_Homey_17.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b382688b2dee1f3875fabdad8b6c4fb2/a0b58/INSTAR_Cameras_with_Athom_Homey_17.webp 230w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/bc10c/INSTAR_Cameras_with_Athom_Homey_17.webp 460w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/966d8/INSTAR_Cameras_with_Athom_Homey_17.webp 920w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/445df/INSTAR_Cameras_with_Athom_Homey_17.webp 1380w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/293e0/INSTAR_Cameras_with_Athom_Homey_17.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b382688b2dee1f3875fabdad8b6c4fb2/81c8e/INSTAR_Cameras_with_Athom_Homey_17.png 230w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/08a84/INSTAR_Cameras_with_Athom_Homey_17.png 460w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/c0255/INSTAR_Cameras_with_Athom_Homey_17.png 920w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/b1001/INSTAR_Cameras_with_Athom_Homey_17.png 1380w", "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/aa440/INSTAR_Cameras_with_Athom_Homey_17.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b382688b2dee1f3875fabdad8b6c4fb2/c0255/INSTAR_Cameras_with_Athom_Homey_17.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`We will start by adding a `}<strong parentName="li">{`When`}</strong>{` condition to tell Homey what has to happen to trigger this flow. Click on `}<strong parentName="li">{`Add card`}</strong>{` to continue.`}</li>
    </ol>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Select the sensor you just added - it should be listed under `}<strong parentName="li">{`Devices`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`In the following menu select what should happen to trigger the flow, e.g. `}<em parentName="li">{`Occupancy sensor detected motion`}</em>{`, `}<em parentName="li">{`Button was pressed`}</em>{`, etc.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d9f3e5bc4714d89922b7bf969e08659/aa440/INSTAR_Cameras_with_Athom_Homey_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDElEQVQoz2N49+rJ1y+f/v7++ffPrz9fv/w/sffs/Mvzwm8tiryzJPz+8vCHK8Ifbg595le5WKplgnT1RMnqCdJVE0Qqp9nWNzJ8evf67ftP33/8+vnrz4+fv//+/39t1/1NHdd2Tn96eO6bI3PfHpn39sy0Tz41c3hLG6RK2qVKO6RL2oUKui3Kyxi+ff/x/cevb99/fvv+8+vXH3/+/9o9916x0aU62yu1llcaLK82Wl7rNb1jlTtVoLZeoqJNsrJVsqJVsLTLvLqU4du3b9++QzV/+frj7/8/uxbeLrA7U+V1scb9Up375Xr3K11ONw3LZ7J3dgq09PG19Ao093I2TdZvrWH49u07RCdE87//f7YuvJlqd7zQ80yh25kSt7OlbucanC7rV05l627ha+3ibevib+3kaO7Xb6vEonnj4uvR9ocyvI5nepzIdj+Z43GyzPm8dvVklr5mns4O7s4Ovo4O1vZenc4KdM3///9Zs/hqoOPeON/D8V6HE72OJnkdzXU7rVE/kWlSE1dPG2dPG3d3K3NXt3ZPGabmv6uXXPR22BzpszPKa1eM1+5Yrz2pzvsV67oYptSz97ey9bdw9rUw9Haq9hQxfPv69du3n9++/fj27ceXL9///v29Zf2F8JDNgT4bg302hXlvCfPakh++13HydO45LSwTG9gnN7FPbGSd3OE8tx4AiWVOWbxOm7UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d9f3e5bc4714d89922b7bf969e08659/e4706/INSTAR_Cameras_with_Athom_Homey_18.avif 230w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/d1af7/INSTAR_Cameras_with_Athom_Homey_18.avif 460w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/7f308/INSTAR_Cameras_with_Athom_Homey_18.avif 920w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/e1c99/INSTAR_Cameras_with_Athom_Homey_18.avif 1380w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/2b305/INSTAR_Cameras_with_Athom_Homey_18.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d9f3e5bc4714d89922b7bf969e08659/a0b58/INSTAR_Cameras_with_Athom_Homey_18.webp 230w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/bc10c/INSTAR_Cameras_with_Athom_Homey_18.webp 460w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/966d8/INSTAR_Cameras_with_Athom_Homey_18.webp 920w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/445df/INSTAR_Cameras_with_Athom_Homey_18.webp 1380w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/293e0/INSTAR_Cameras_with_Athom_Homey_18.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d9f3e5bc4714d89922b7bf969e08659/81c8e/INSTAR_Cameras_with_Athom_Homey_18.png 230w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/08a84/INSTAR_Cameras_with_Athom_Homey_18.png 460w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/c0255/INSTAR_Cameras_with_Athom_Homey_18.png 920w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/b1001/INSTAR_Cameras_with_Athom_Homey_18.png 1380w", "/en/static/8d9f3e5bc4714d89922b7bf969e08659/aa440/INSTAR_Cameras_with_Athom_Homey_18.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d9f3e5bc4714d89922b7bf969e08659/c0255/INSTAR_Cameras_with_Athom_Homey_18.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Confirm your selection.`}</li>
    </ol>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`And swipe up to get to the `}<strong parentName="li">{`Then`}</strong>{` condition to select what should happen, when the flow was triggered.`}</li>
    </ol>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Add card`}</strong>{` to select an action for the flow.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ef84790a21392bf774c3daf036b72f29/aa440/INSTAR_Cameras_with_Athom_Homey_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFUlEQVQozzWOS2sTUQCFr8ZJSPNAaqshrV0IgpsufaAFtyJ060rsWnEhWvOa2GSmd57JzCSZGrWtpIIpWiixPppQrRttGtQi9AeILtQqSTU1mWdmrjTo4eOszgcHHIO5wxPZAC4FY5lBPHtgXEwvlfXm9k7zd8fQTF3VNQWZ6lih2I+LA/FMICYFo2JfZGpkIgEOxlh/iO0NcX0hvv8m77wKk4VHqN22ELIt27aR2bERQhfniu5xKhgXgrg4EBV6w9lTOA5mKpXFN+ulau1p7cPS+vvljc3KWlVptdH/WPauLL5cubbwhCivkuXX5PJqovyWW3wAqpsbnz5/+bq19bPR+FGvK4ZZ+/iu0agjhOyu1un2hfmHXsgPpeVBXh7icofYu6e5JDguZ0/Kt8/k743kp8/emTkhT4uvnunqn93bXcyuPFqcA0nCx3AeivNBFiPFYRgBGAf3MpSDhg6KdtEMuJWMPi8Zv7a1VstSVUtVDUVBnc5ocRZQhC/FeTjWz7IYIwyzEeCVoFugewTak2b8aRbQ5OSLUvvb92a9YWvaP9k0zy3cBzzhFWh3mvakKAefOipFwT4pgWWgMwNdEtUjMYBJ3Cg/Vneaiq5bCFkImZaFEBqbFdyJ69gU65KgW4RA4M6HL4EjBcEhE06ZcOVIV5bcLzPs2oqhaqpuaF0UTbcRis/nA1x4T36yOyOwHHuFvvwX6K5OL7BA0NYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef84790a21392bf774c3daf036b72f29/e4706/INSTAR_Cameras_with_Athom_Homey_19.avif 230w", "/en/static/ef84790a21392bf774c3daf036b72f29/d1af7/INSTAR_Cameras_with_Athom_Homey_19.avif 460w", "/en/static/ef84790a21392bf774c3daf036b72f29/7f308/INSTAR_Cameras_with_Athom_Homey_19.avif 920w", "/en/static/ef84790a21392bf774c3daf036b72f29/e1c99/INSTAR_Cameras_with_Athom_Homey_19.avif 1380w", "/en/static/ef84790a21392bf774c3daf036b72f29/2b305/INSTAR_Cameras_with_Athom_Homey_19.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ef84790a21392bf774c3daf036b72f29/a0b58/INSTAR_Cameras_with_Athom_Homey_19.webp 230w", "/en/static/ef84790a21392bf774c3daf036b72f29/bc10c/INSTAR_Cameras_with_Athom_Homey_19.webp 460w", "/en/static/ef84790a21392bf774c3daf036b72f29/966d8/INSTAR_Cameras_with_Athom_Homey_19.webp 920w", "/en/static/ef84790a21392bf774c3daf036b72f29/445df/INSTAR_Cameras_with_Athom_Homey_19.webp 1380w", "/en/static/ef84790a21392bf774c3daf036b72f29/293e0/INSTAR_Cameras_with_Athom_Homey_19.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef84790a21392bf774c3daf036b72f29/81c8e/INSTAR_Cameras_with_Athom_Homey_19.png 230w", "/en/static/ef84790a21392bf774c3daf036b72f29/08a84/INSTAR_Cameras_with_Athom_Homey_19.png 460w", "/en/static/ef84790a21392bf774c3daf036b72f29/c0255/INSTAR_Cameras_with_Athom_Homey_19.png 920w", "/en/static/ef84790a21392bf774c3daf036b72f29/b1001/INSTAR_Cameras_with_Athom_Homey_19.png 1380w", "/en/static/ef84790a21392bf774c3daf036b72f29/aa440/INSTAR_Cameras_with_Athom_Homey_19.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ef84790a21392bf774c3daf036b72f29/c0255/INSTAR_Cameras_with_Athom_Homey_19.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#control-your-camera-manually"
        }}>{`In a previous step`}</a>{` we created a virtual button that sends a CGI command to our camera to have it move to a preset position. I modified it by adding a second card to it, so that now I have two cameras connected to it - you can add as many as you want. `}<em parentName="li">{`A usage example would be`}</em>{`: if a motion sensor in the front of the house is triggered, all cameras in the back turn around to see what caused it.`}</li>
    </ol>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">{`We can select this virtual button in the list of `}<strong parentName="li">{`Devices`}</strong>{` to be triggered once the flow is triggered.`}</li>
    </ol>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`You can now test the flow and save it.`}</li>
    </ol>
    <h2 {...{
      "id": "time-scheduled-camera-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#time-scheduled-camera-control",
        "aria-label": "time scheduled camera control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Time scheduled Camera control`}</h2>
    <h3 {...{
      "id": "daynight-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#daynight-schedule",
        "aria-label": "daynight schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day&Night Schedule`}</h3>
    <p>{`Homey allows us to trigger flows at `}<strong parentName="p">{`Sunset`}</strong>{` and `}<strong parentName="p">{`Sunrise`}</strong>{` solving problems that often come up when installing IP surveillance cameras. Examples are to `}<em parentName="p">{`let your camera move to a different preset position for day or night`}</em>{`, `}<em parentName="p">{`set different motion detection areas for day and night`}</em>{` or `}<em parentName="p">{`or reduce the motion detection sensitivity during the night`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95aa0cbcc061930e822cb29c04650c23/aa440/INSTAR_Cameras_with_Athom_Homey_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVQoz02P3U9SYQCHz9/Q1p1r/QldVGNQSfHV4fDlhNACMw03yEEmJAUWks6IVm1ljdWac27miEUIDjEVhA46DJZCYIJ3XTSaM53nHM7H+za7qd/Fc/s8PwQzhRSXI236uEmXdGjS4xfGA6rMS7Q0dr74SlgKNJffCCuveZXa8vY+tUMeHLBMg2kQNAvJn0WkwxPX9ScM1kVzb8ZuyXksxYca3MtLjfAzvjO4X4A/FmT9J1Y2J9MMvQ9JGpAUIAhAQ1AvIzpPRO2Iqq2z+t5EtzlpM+Nubfa+qOyVVIZFm6Oi74/E1VF+NT9TAPQeJBuQoiBJQAbCehlp933UDcU0A1HNrVm9NdFtTfbpk7dFeaekcFf8dVC87pFsDPI3CktrNCAggIcD3CH3thG1773CG5a7wpgjouqLtdri19sSVmnOJsv1Sdfs0i9OWcF+Nl9IfGMhCTgIAAAcCyAEuzVE/TQoHg7KPCG5O6x0zqgc0SuGqEmG96C4+eLKDdmqDc1Zzq2m5ysQsgzDsSzHMgwLIb2zhSievxP5piUjQdlQSO7+oHBFdFfDBjRpxFKdaLpLnumR413Cz/EQznAEhP9l/64hvGdvT/kmTnonTt+b5N+ZEgxMY53BFnReq/h0CVtox5aMypS2eTEzV6BZgqb/mdndKnL0gf+I+0mT60VT/9jxm4Fj5gBqnjJ2LKCKmEoVb1HOtSoT1/TJ9Wyp/usHRVEQAsAxfz9v/QEQFV8bo4Ch7gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95aa0cbcc061930e822cb29c04650c23/e4706/INSTAR_Cameras_with_Athom_Homey_20.avif 230w", "/en/static/95aa0cbcc061930e822cb29c04650c23/d1af7/INSTAR_Cameras_with_Athom_Homey_20.avif 460w", "/en/static/95aa0cbcc061930e822cb29c04650c23/7f308/INSTAR_Cameras_with_Athom_Homey_20.avif 920w", "/en/static/95aa0cbcc061930e822cb29c04650c23/e1c99/INSTAR_Cameras_with_Athom_Homey_20.avif 1380w", "/en/static/95aa0cbcc061930e822cb29c04650c23/2b305/INSTAR_Cameras_with_Athom_Homey_20.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95aa0cbcc061930e822cb29c04650c23/a0b58/INSTAR_Cameras_with_Athom_Homey_20.webp 230w", "/en/static/95aa0cbcc061930e822cb29c04650c23/bc10c/INSTAR_Cameras_with_Athom_Homey_20.webp 460w", "/en/static/95aa0cbcc061930e822cb29c04650c23/966d8/INSTAR_Cameras_with_Athom_Homey_20.webp 920w", "/en/static/95aa0cbcc061930e822cb29c04650c23/445df/INSTAR_Cameras_with_Athom_Homey_20.webp 1380w", "/en/static/95aa0cbcc061930e822cb29c04650c23/293e0/INSTAR_Cameras_with_Athom_Homey_20.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95aa0cbcc061930e822cb29c04650c23/81c8e/INSTAR_Cameras_with_Athom_Homey_20.png 230w", "/en/static/95aa0cbcc061930e822cb29c04650c23/08a84/INSTAR_Cameras_with_Athom_Homey_20.png 460w", "/en/static/95aa0cbcc061930e822cb29c04650c23/c0255/INSTAR_Cameras_with_Athom_Homey_20.png 920w", "/en/static/95aa0cbcc061930e822cb29c04650c23/b1001/INSTAR_Cameras_with_Athom_Homey_20.png 1380w", "/en/static/95aa0cbcc061930e822cb29c04650c23/aa440/INSTAR_Cameras_with_Athom_Homey_20.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95aa0cbcc061930e822cb29c04650c23/c0255/INSTAR_Cameras_with_Athom_Homey_20.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Go to the `}<strong parentName="li">{`Flows`}</strong>{` tab and click on the `}<strong parentName="li">{`+`}</strong>{` icon in the top right to add a new flow.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Add card`}</strong>{` to continue.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Now select `}<strong parentName="li">{`Date&Time`}</strong>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a07cb4930b655de1f87021e62fb317c/aa440/INSTAR_Cameras_with_Athom_Homey_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnklEQVQoz43NTUsbURSH8flKLlwIUrHaTpxBMPE1RVARLBYUMaILEwmiqyxKi5QWiQitkpU2i9BqFNSJGCUKicZCi9YWtNVRI2buua+TK40RU3Ah/DYHzsNfMU9+I5S1BROcCk4ZI9bFccb8Kzi1GbFzQpimvR79Ovot6Pr+6cXP2eajUPNRqPH0S8e6cnV4yDCWhOTyJGPU/HN5dg6YAiaEMMJETkrj/X7k9S9j+sIInseCZmwKbU3sKKd7KQogKZOU3uJWBmGKEEZA/kFESDrZm/aUJH3luyNl6dGyPX/pwbtnS0rGPMOMC2HzOxSu87MFFqK25B8Gkn0V214t6VVTfjXlq/4xUbeoYEIA08JIHmSv/o9JTvI3AztdT+L9jkT/88Sguu2pSgfqIgoAAGbF3/ihODC41VphdOsb3Wq8x7H5qjo55gwrALj49cFYSj42tOF6utxWu9auGZ1arF1NDDfMPTIW/qFVrTLiro26tWirvuRWYx7njAIIAVAAcu/6svi0LGzb7G1gxanP1+ufm/RwS03YrS2Mv/x4AzovuUuxRs0+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a07cb4930b655de1f87021e62fb317c/e4706/INSTAR_Cameras_with_Athom_Homey_21.avif 230w", "/en/static/4a07cb4930b655de1f87021e62fb317c/d1af7/INSTAR_Cameras_with_Athom_Homey_21.avif 460w", "/en/static/4a07cb4930b655de1f87021e62fb317c/7f308/INSTAR_Cameras_with_Athom_Homey_21.avif 920w", "/en/static/4a07cb4930b655de1f87021e62fb317c/e1c99/INSTAR_Cameras_with_Athom_Homey_21.avif 1380w", "/en/static/4a07cb4930b655de1f87021e62fb317c/2b305/INSTAR_Cameras_with_Athom_Homey_21.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a07cb4930b655de1f87021e62fb317c/a0b58/INSTAR_Cameras_with_Athom_Homey_21.webp 230w", "/en/static/4a07cb4930b655de1f87021e62fb317c/bc10c/INSTAR_Cameras_with_Athom_Homey_21.webp 460w", "/en/static/4a07cb4930b655de1f87021e62fb317c/966d8/INSTAR_Cameras_with_Athom_Homey_21.webp 920w", "/en/static/4a07cb4930b655de1f87021e62fb317c/445df/INSTAR_Cameras_with_Athom_Homey_21.webp 1380w", "/en/static/4a07cb4930b655de1f87021e62fb317c/293e0/INSTAR_Cameras_with_Athom_Homey_21.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a07cb4930b655de1f87021e62fb317c/81c8e/INSTAR_Cameras_with_Athom_Homey_21.png 230w", "/en/static/4a07cb4930b655de1f87021e62fb317c/08a84/INSTAR_Cameras_with_Athom_Homey_21.png 460w", "/en/static/4a07cb4930b655de1f87021e62fb317c/c0255/INSTAR_Cameras_with_Athom_Homey_21.png 920w", "/en/static/4a07cb4930b655de1f87021e62fb317c/b1001/INSTAR_Cameras_with_Athom_Homey_21.png 1380w", "/en/static/4a07cb4930b655de1f87021e62fb317c/aa440/INSTAR_Cameras_with_Athom_Homey_21.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a07cb4930b655de1f87021e62fb317c/c0255/INSTAR_Cameras_with_Athom_Homey_21.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Here you can choose a specific time to trigger the flow or select `}<strong parentName="li">{`Sunrise`}</strong>{` or `}<strong parentName="li">{`Sunset`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Confirm your selection.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`And swipe up to the `}<strong parentName="li">{`Then`}</strong>{` condition of the flow.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/71dd006b5e00573146686dc366bcf425/aa440/INSTAR_Cameras_with_Athom_Homey_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACGElEQVQozx3Oy08TQQCA8dHSNn0tEcTQx8UDXvXozYMxPjh7McbEoz14wFaXtrRsuzv76G532xqMMS2iQgjRmhqtBAIJCQlGE736PwiEtrA7M/sYA8nv+B0+MFVpxufq8byRyNdT+cZYVofdHh4eYmS6BLkE2RhR23zYXh6f1ZIFI5EzUjl9gl24UeJAihUmMkI8KyYzUjIjMely8VOXEMvExHY923WR41JK77c++GYq4zl1jFUuPlfCz2rXWBbEc+VLLD95SkiwYvTpPOx8pRib/QEanLL6Q+94sLiz2d7aWd398fnn7497v7p//n7b7YFRWI0JGsNrTKU2zusjBWX+S88d9I/+7Q8PDocHh/39A69/lO6sXX/15nb7/fS71em3K3eWOo/bDcBIYlSUYlBmBOUCVM/PCdzGJvWohYnjeo7r4bPtW0stUCwFeNHHCYF5ARSVK1wWRKswrEgRRYrI8qisAK5S2linnmdhQmyHENvChLru3eUWgBxTlWOKxMhSQNKuyiyI6HxQgyEVhqowpkqAL5e21qlHTYQJwgQTRBxq2zfXWkDhopoYUmGkKviU6pQ+C0b0kt/gAwYf1IWwIQJYLGx/p5SeIGxZCCFsIkwpffBaDZdm/C/FoM6HajzQ5HsvHoHLi5qvyQWaXLBRDtbLTBNqe9uUuMMTy7SQaaLjE8uxHXZlYVJizy1UzjLO35DS8Ml/2Og+OIkWO6MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71dd006b5e00573146686dc366bcf425/e4706/INSTAR_Cameras_with_Athom_Homey_22.avif 230w", "/en/static/71dd006b5e00573146686dc366bcf425/d1af7/INSTAR_Cameras_with_Athom_Homey_22.avif 460w", "/en/static/71dd006b5e00573146686dc366bcf425/7f308/INSTAR_Cameras_with_Athom_Homey_22.avif 920w", "/en/static/71dd006b5e00573146686dc366bcf425/e1c99/INSTAR_Cameras_with_Athom_Homey_22.avif 1380w", "/en/static/71dd006b5e00573146686dc366bcf425/2b305/INSTAR_Cameras_with_Athom_Homey_22.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/71dd006b5e00573146686dc366bcf425/a0b58/INSTAR_Cameras_with_Athom_Homey_22.webp 230w", "/en/static/71dd006b5e00573146686dc366bcf425/bc10c/INSTAR_Cameras_with_Athom_Homey_22.webp 460w", "/en/static/71dd006b5e00573146686dc366bcf425/966d8/INSTAR_Cameras_with_Athom_Homey_22.webp 920w", "/en/static/71dd006b5e00573146686dc366bcf425/445df/INSTAR_Cameras_with_Athom_Homey_22.webp 1380w", "/en/static/71dd006b5e00573146686dc366bcf425/293e0/INSTAR_Cameras_with_Athom_Homey_22.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71dd006b5e00573146686dc366bcf425/81c8e/INSTAR_Cameras_with_Athom_Homey_22.png 230w", "/en/static/71dd006b5e00573146686dc366bcf425/08a84/INSTAR_Cameras_with_Athom_Homey_22.png 460w", "/en/static/71dd006b5e00573146686dc366bcf425/c0255/INSTAR_Cameras_with_Athom_Homey_22.png 920w", "/en/static/71dd006b5e00573146686dc366bcf425/b1001/INSTAR_Cameras_with_Athom_Homey_22.png 1380w", "/en/static/71dd006b5e00573146686dc366bcf425/aa440/INSTAR_Cameras_with_Athom_Homey_22.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/71dd006b5e00573146686dc366bcf425/c0255/INSTAR_Cameras_with_Athom_Homey_22.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Add card`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Logic`}</strong>{` from the following list and choose to `}<strong parentName="li">{`Make a web request`}</strong>{`. Here you can `}<a parentName="li" {...{
          "href": "/en/Advanced_User/CGI_Commands/"
        }}>{`a CGI command`}</a>{` of your choice to be send to your camera when the flow is triggered:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.116:80/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`preset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-act`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`goto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-number`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Confirm your selection and `}<strong parentName="li">{`Test`}</strong>{` and `}<strong parentName="li">{`Save`}</strong>{` it. All done.`}</li>
    </ol>
    <h3 {...{
      "id": "manual-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#manual-schedule",
        "aria-label": "manual schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Schedule`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f906b969d333e4c3906a0bc0606a2379/aa440/INSTAR_Cameras_with_Athom_Homey_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEElEQVQoz13N3UtTYQAG8ONlXVXQhQX9DXkrRAQR3UQUqDues8qdTw0LB6ul+VEblMJmBJtNL8zcV3Z2NudiBSNss7XNLjbZJppbEFGoEdbOed+9+3qjCUE9PBfPzY+H2C1uqj++19VSTflVLf3ECL58MU93d/WKnMgzIs8IPHNDYE6Pmk+YbMeGrK2Dj44PTh69Yzs1MkbsFQsVADEsN2C5pgKMcWjBTWvI64IosJzIciIn9LG6NuPwAeP4IcODw7cmjhgeHtRb2m4bie3CR1Sv42bqzREOyRdo8ryguyRyFwX2HK8jr9Jm5zNfJudP5wKZfCCdDWQLcjRE7O1sQ1SpVWvVag2hyp9nydMxNtQZCfa8krVhX2ckSA30uxa9G+v5D8lkKvE+GV/JruWL6zEClhGAFRUgFaCSAjHGXvfcc6cTY/zp29cvuzsY43GzySE5E6uroXD4dSSyHI2+fZeMxwIEAADACoAIQKSo/2CgKFBVm/i+Q3bntrYS6XQqk0ll0tnC543NOAEA3Jd/sSx5DPqBpzMz01M2h9027XjS39c7FXQtr8RkSfL5pIBfXloKv4n6/scNjIP+hQ5NF6mlNVQ3SVEkRV3puUaZjWftlpOz9vY5xxnPbLtr3jChJ4CqAoAAKANQVhTQaOBF2UtrKUHkOZ7dL8PqLt+72Wq9SzwebZkcabEME1YzY+J/A33bcq042gTwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f906b969d333e4c3906a0bc0606a2379/e4706/INSTAR_Cameras_with_Athom_Homey_23.avif 230w", "/en/static/f906b969d333e4c3906a0bc0606a2379/d1af7/INSTAR_Cameras_with_Athom_Homey_23.avif 460w", "/en/static/f906b969d333e4c3906a0bc0606a2379/7f308/INSTAR_Cameras_with_Athom_Homey_23.avif 920w", "/en/static/f906b969d333e4c3906a0bc0606a2379/e1c99/INSTAR_Cameras_with_Athom_Homey_23.avif 1380w", "/en/static/f906b969d333e4c3906a0bc0606a2379/2b305/INSTAR_Cameras_with_Athom_Homey_23.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f906b969d333e4c3906a0bc0606a2379/a0b58/INSTAR_Cameras_with_Athom_Homey_23.webp 230w", "/en/static/f906b969d333e4c3906a0bc0606a2379/bc10c/INSTAR_Cameras_with_Athom_Homey_23.webp 460w", "/en/static/f906b969d333e4c3906a0bc0606a2379/966d8/INSTAR_Cameras_with_Athom_Homey_23.webp 920w", "/en/static/f906b969d333e4c3906a0bc0606a2379/445df/INSTAR_Cameras_with_Athom_Homey_23.webp 1380w", "/en/static/f906b969d333e4c3906a0bc0606a2379/293e0/INSTAR_Cameras_with_Athom_Homey_23.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f906b969d333e4c3906a0bc0606a2379/81c8e/INSTAR_Cameras_with_Athom_Homey_23.png 230w", "/en/static/f906b969d333e4c3906a0bc0606a2379/08a84/INSTAR_Cameras_with_Athom_Homey_23.png 460w", "/en/static/f906b969d333e4c3906a0bc0606a2379/c0255/INSTAR_Cameras_with_Athom_Homey_23.png 920w", "/en/static/f906b969d333e4c3906a0bc0606a2379/b1001/INSTAR_Cameras_with_Athom_Homey_23.png 1380w", "/en/static/f906b969d333e4c3906a0bc0606a2379/aa440/INSTAR_Cameras_with_Athom_Homey_23.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f906b969d333e4c3906a0bc0606a2379/c0255/INSTAR_Cameras_with_Athom_Homey_23.png",
              "alt": "INSTAR Kameras und Athom Homey",
              "title": "INSTAR Kameras und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Another example is to activate the alarm on all your cameras every morning when you leave for work and deactivate it before you arrive back home.`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setscheduleex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-ename`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`md`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week0`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-week6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-usr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`USERNAME`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-pwd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`PASSWORD`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In this command, week0 - week6 stands for Monday through Sunday and each P is half an hour of the day the alarm schedule is active (48 x 30min = 24h). An N equals a 30-minute period in which it is disabled. The schedule activates / deactivates all alarm actions.`}</p>
    <p>{`More `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Athom_Homey/"
      }}>{`examples can be found here`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      